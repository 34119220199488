import { render, staticRenderFns } from "./detailChart.vue?vue&type=template&id=54714b22&scoped=true"
import script from "./detailChart.vue?vue&type=script&lang=js"
export * from "./detailChart.vue?vue&type=script&lang=js"
import style0 from "./detailChart.vue?vue&type=style&index=0&id=54714b22&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54714b22",
  null
  
)

export default component.exports